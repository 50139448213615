// -----------------------------
// BigFuture Fonts
// https://fonts.google.com/specimen/Unbounded
// -----------------------------

@import "apricot-bf-variable.scss";
@import "../mixins/font.scss";
@import "../mixins/breakpoint.scss";

// Unbounded Fonts
@include font-face-light(Unbounded, Unbounded-ExtraLight, 200, normal);
@include font-face-light(Unbounded, Unbounded-Light, 300, normal);
@include font-face-light(Unbounded, Unbounded-Regular, 400, normal);
@include font-face-light(Unbounded, Unbounded-Medium, 500, normal);
@include font-face-light(Unbounded, Unbounded-SemiBold, 600, normal);
@include font-face-light(Unbounded, Unbounded-Bold, 700, normal);
@include font-face-light(Unbounded, Unbounded-ExtraBold, 800, normal);
@include font-face-light(Unbounded, Unbounded-Black, 900, normal);

// Headings
.cb-bf-project {
  @for $i from 1 through 6 {
    h#{$i},
    .cb-bf-h#{$i} {
      font-family: Unbounded !important;
      font-size: map-get(map-get($heading, h#{$i}), size);
      line-height: map-get(map-get($heading, h#{$i}), line);
    }
  }
  h1,
  .cb-bf-h1 {
    font-size: 2rem; //32
    line-height: 1.25em; //40
  }

  h2,
  .cb-bf-h2 {
    font-size: 1.75rem; //28
    line-height: 1.2857142857142858em; //36
  }

  h3,
  .cb-bf-h3 {
    font-size: 1.5rem; //24
    line-height: 1.3333333333333333em; //32
  }

  h4,
  .cb-bf-h4 {
    font-size: 1.75rem; //20
    line-height: 1.4em; //28
  }

  h5,
  .cb-bf-h5 {
    font-size: 1rem; //16
    line-height: 1.5em; //24
  }

  h6,
  .cb-bf-h6 {
    font-size: 0.875rem; //14
    line-height: 1.2857142857142858em; //18
  }

  // sm, md
  @include media-breakpoint-up("sm", $grid-breakpoints) {
    h1,
    .cb-bf-h1 {
      font-size: 3rem; //48
      line-height: 1.1666666666666667em; //56
    }

    h2,
    .cb-bf-h2 {
      font-size: 2.5rem; //40
      line-height: 1.2em; //48
    }

    h3,
    .cb-bf-h3 {
      font-size: 1.75rem; //32
      line-height: 1.25em; //40
    }

    h4,
    .cb-bf-h4 {
      font-size: 1.5rem; //24
      line-height: 1.3333333333333333em; //32
    }

    h5,
    .cb-bf-h5 {
      font-size: 1.25rem; //20
      line-height: 1.4em; //28
    }

    h6,
    .cb-bf-h6 {
      font-size: 1rem; //16
      line-height: 1.5em; //24
    }
  }

  //lg, xl, 2xl, 3xl
  @include media-breakpoint-up("lg", $grid-breakpoints) {
    h1,
    .cb-bf-h1 {
      font-size: 3.5rem; //56
      line-height: 1.1428571428571428em; //64
    }

    h2,
    .cb-bf-h2 {
      font-size: 3rem; //48
      line-height: 1.1666666666666667em; //56
    }

    h3,
    .cb-bf-h3 {
      font-size: 2.5rem; //40
      line-height: 1.2em; //48
    }

    h4,
    .cb-bf-h4 {
      font-size: 2rem; //32
      line-height: 1.25em; //40
    }

    h5,
    .cb-bf-h5 {
      font-size: 1.5rem; //24
      line-height: 1.3333333333333333em; //32
    }

    h6,
    .cb-bf-h6 {
      font-size: 1.125rem; //18
      line-height: 1.4444444444444444em; //26
    }
  }
}

//  Unbounded Classes
.cb-unbounded-extra-light {
  @include family(Unbounded);
  font-weight: 200;
}
.cb-unbounded-light {
  @include family(Unbounded);
  font-weight: 300;
}
.cb-unbounded {
  @include family(Unbounded);
  font-weight: 400;
}
.cb-unbounded-medium {
  @include family(Unbounded);
  font-weight: 500;
}
.cb-unbounded-semi-bold {
  @include family(Unbounded);
  font-weight: 600;
}
.cb-unbounded-bold {
  @include family(Unbounded);
  font-weight: 700;
}
.cb-unbounded-extra-bold {
  @include family(Unbounded);
  font-weight: 800;
}
.cb-unbounded-black {
  @include family(Unbounded);
  font-weight: 900;
}

// Headings
