// -----------------------------
// BigFuture Colors
// -----------------------------

@import "apricot-bf-variable.scss";

@each $color, $map in $colorThemes {
  @each $key, $value in $map {
    .#{"cb-bf-" + $color + "-" + $key} {
      background-color: $value;
    }
    .#{"cb-bf-" + $color + "-" + $key + "-color"} {
      color: $value;
    }
  }
}