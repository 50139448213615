// -----------------------------
// BigFuture, Global Header
// -----------------------------

:root cbw-header {
  --cbw-cb-logo-font: BF Icons Logo!important;
  --cbw-cb-logo-bg: #fff;
  --cbw-cb-logo-color: #1e1e1e;
}
@media (max-width: 767.5px) {
  :root cbw-header {
    --cbw-cb-logo-bg: #1e1e1e;
    --cbw-cb-logo-color: #fff;
  }
}
