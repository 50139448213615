// -----------------------------
// BigFuture Icons
// -----------------------------

.cb-icon {
  &.cb-icon-circular {
    &[class*="tint-"],
    &[class*="chartreuse-"],
    &[class*="neutral-"]{
        &::before {
          color: $black1!important;
        }
    }
  }

  // Background color
  @each $color, $map in $colorThemes {
    @each $key, $value in $map {
      &.#{"cb-bf-" + $color + "-" + $key + "-color"} {
        &.cb-icon-circular {
          &::after {
            background-color: $value;
          }
        }
      }
    }
  }
}
