// -----------------------------
// BigFuture Article Cards
// BigFuture Horizontal Carousel
// -----------------------------

@import "apricot-bf-variable.scss";
@import "../mixins/breakpoint.scss";

// Article Card
.cb-bf-article-card {
  width: 326px;
  padding: 0 !important;
  background-repeat: no-repeat;
  background-size: contain;
  .cb-card-content {
    height: 100%;
    margin-top: 200px;
    padding: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    .cb-card-title {
      font-size: 1.125rem;
      line-height: 1.3333333333333333em;
      font-weight: 700;
    }
    .cb-card-desc {
      margin-top: 8px;
      font-size: 1rem;
      line-height: 1.5em;
      font-weight: 400;
    }
    @include media-breakpoint-down("sm", $grid-breakpoints) {
      .cb-card-title {
        font-size: 0.75rem;
        line-height: 1.3333333333333333em;
      }
      .cb-card-desc {
        font-size: 0.75rem;
        line-height: 1.3333333333333333em;
        font-weight: 400;
      }
    }

    &.cb-white-color {
      a:visited {
        color: $white;
      }
      .cb-card-title,
      .cb-card-desc {
        color: $white;
      }
    }
  }

  // TBD
  &.cb-white-color {
    .cb-card-title,
    .cb-card-desc {
      color: $white;
    }
  }
  + .cb-bf-article-card {
    margin-top: 24px;
  }
}

// Carousel
.cb-bf-article-carousel {
  display: flex;
  list-style: none;
  padding-left: 0;

  .cb-horizontal-nav {
    a {
      opacity: unset;
      background-color: rgba(0, 16, 117, 0.75) !important;
    }
  }
  .cb-bf-article-card {
    margin-top: 0;
    + .cb-bf-article-card {
      margin-left: 24px;
    }
  }
  @include media-breakpoint-only("xs", $grid-breakpoints) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    .cb-bf-article-card {
      margin-left: 0 !important;
      + .cb-bf-article-card {
        margin-top: 24px;
      }
    }
  }
}
