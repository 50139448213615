// -----------------------------
// BigFuture Hero Band
// -----------------------------

@import "apricot-bf-variable.scss";
@import "../mixins/breakpoint.scss";

.cb-band {
  &.cb-band-hero {
    &.cb-bf-band-hero {
      position: relative;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: cover;
      .cb-bf-band-hero-overlay {
        width: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        transition: width $transition-time-s ease-in-out;
        // &.cb-bf-blue-shade-2 {
        //   opacity: 75%;
        // }
        // &.cb-bf-blue-tint-2 {
        //   opacity: 90%;
        // }
        &.cb-bf-band-hero-overlay-left {
          left: 0;
        }
        &.cb-bf-band-hero-overlay-right {
          left: 50%;
        }
      }
      .row {
        &:first-of-type {
          min-height: 350px;
        }
      }
      .cb-band-hero-content {
        .cb-band-hero-title {
          font-size: 3rem;
          line-height: 1.1666666666666667em;
          font-weight: 700;
          + .cb-band-hero-desc {
            margin-top: 24px;
          }
        }

        .cb-band-hero-desc {
          font-size: 2rem;
          line-height: 1.25em;
        }
        .cb-btn-row {
          margin-top: 8px;
        }
      }

      @include media-breakpoint-down("md", $grid-breakpoints) {
        .row {
          &:first-of-type {
            min-height: 220px;
          }
        }
        .cb-band-hero-content {
          padding-top: 48px 0;
          .cb-btn-row {
            margin-top: 0;
          }
        }
      }

      @include media-breakpoint-up("2xl", $grid-breakpoints) {
        .cb-band-hero-content {
          padding-left: 32px;
          padding-right: 60px;
        }
      }
      @include media-breakpoint-only("xl", $grid-breakpoints) {
        .cb-band-hero-content {
          padding-left: 0;
          padding-right: 48px;
        }
      }
      @include media-breakpoint-only("lg", $grid-breakpoints) {
        .cb-band-hero-content {
          padding-left: 0;
          padding-right: 32px;
        }
      }

      @include media-breakpoint-only("md", $grid-breakpoints) {
        .cb-band-hero-content {
          padding-left: 0;
          padding-right: 32px;
          .cb-band-hero-desc {
            font-size: 1.625rem;
            line-height: 1.2307692307692308em;
            font-weight: 300;
          }
        }
      }
      @include media-breakpoint-only("sm", $grid-breakpoints) {
        .cb-band-hero-content {
          padding-left: 0;
          padding-right: 24px;
          .cb-band-hero-title {
            font-size: 2rem;
            line-height: 1.25em;
            font-weight: 700;
          }
          .cb-band-hero-desc {
            font-size: 1rem;
            line-height: 1.5em;
            font-weight: 300;
          }
        }
      }
      @include media-breakpoint-only("xs", $grid-breakpoints) {
        .cb-bf-band-hero-overlay {
          left: 0 !important;
          margin-top: 320px;
          width: 100%;
        }
        .cb-band-hero-content {
          margin-top: 320px;
          padding: 40px 0;
          .cb-band-hero-title {
            letter-spacing: unset;
            font-size: 1.75rem;
            line-height: 1.2857142857142858em;
            font-weight: 700;
            + .cb-band-hero-desc {
              margin-top: 16px;
            }
          }
          .cb-band-hero-desc {
            font-size: 1rem;
            line-height: 1.5em;
          }
          .cb-btn-row {
            margin-top: 0;
          }
        }
      }
      // right aligned panel
      .offset-sm-6 {
        @include media-breakpoint-only("xl", $grid-breakpoints) {
          .cb-band-hero-content {
            padding-left: 32px;
            padding-right: 0;
          }
        }
        @include media-breakpoint-only("lg", $grid-breakpoints) {
          .cb-band-hero-content {
            padding-left: 32px;
            padding-right: 0;
          }
        }
        @include media-breakpoint-only("md", $grid-breakpoints) {
          .cb-band-hero-content {
            padding-left: 32px;
            padding-right:0;
          }
        }
        @include media-breakpoint-only("sm", $grid-breakpoints) {
          .cb-band-hero-content {
            padding-left: 24px;
            padding-right: 0;
          }
        }
      }
    }
  }
}
